import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import debounce from "lodash/debounce";
import { store } from "../../../../../redux/storeConfig/store";
import { generateNote } from "../../../../../redux/slices/notes/notes.api";

const MySwal = withReactContent(Swal);
// ** Get all Data
export const getAllData = () => {
  return async (dispatch) => {
    await axios.get("/org/all").then((response) => {
      // console.warn(response);
      dispatch({
        type: "GET_ALL_TEAMS_DATA",
        data: response.data.data,
        count: response.data.data.length,
      });
    });
  };
};

// ** Get data on page or row change
export const searchAndFilterTeam = (params) => {
  return async (dispatch) => {
    if (params?.debounce) {
      debouncedSearch(params)?.catch((err) => console.log(err));
    } else {
      const { page, perPage, status, q, assigned } = params || {};
      await axios
        .get("/org/search", {
          params: {
            page,
            page_size: perPage,
            status,
            text: q,
            ...(params?.hasOwnProperty("assigned")
              ? { assigned: assigned ? "true" : "false" }
              : {}),
          },
        })
        .then((response) => {
          dispatch({
            type: "SEARCH_TEAMS_DATA",
            data: response.data.data.rows,
            total: response.data.data.total_items,
            params,
          });
        })
        .catch((err) => console.log(err));
    }
  };
};

export const debouncedSearch = debounce(async (params) => {
  const { page, perPage, status, q } = params || {};
  const response = await axios.get("/org/search", {
    params: {
      page,
      page_size: perPage,
      status,
      text: q,
    },
  });
  if (response.status === 200) {
    store.dispatch({
      type: "SEARCH_TEAMS_DATA",
      data: response.data.data.rows,
      total: response.data.data.total_items,
      params,
    });
  } else {
    throw new Error(response?.error?.message || "");
  }
}, 1000);

// ** Get team
export const getTeam = (id) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_TEAM_LOADER", visiblity: true });
    dispatch({ type: "RESET_TEAM_INFO" });
    await axios
      .get(`/org/details/${id}`)
      .then((response) => {
        dispatch({
          type: "GET_TEAM",
          selectedTeam: response.data.data,
        });
      })
      .catch((err) => console.log(err))
      .then(() =>
        dispatch({ type: "TOGGLE_GET_TEAM_LOADER", visiblity: false })
      );
  };
};

export const searchAndFilterTeamCustomers = (orgId, params) => {
  return async (dispatch) => {
    const { page, perPage, q } = params || {};
    await axios
      .get(`/customer/org/${orgId}/search`, {
        params: {
          page,
          page_size: perPage,
          text: q,
        },
      })
      .then((response) => {
        dispatch({
          type: "GET_TEAM_CUSTOMERS",
          data: response.data.data.data,
          count: response.data.data.total_items,
          params,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const searchAndFilterTeamNotes = (orgId, params) => {
  return async (dispatch) => {
    const { page, perPage, q } = params || {};
    await axios
      .get(`/note/org/${orgId}/search`, {
        params: {
          page,
          page_size: perPage,
          text: q,
        },
      })
      .then((response) => {
        dispatch({
          type: "GET_TEAM_NOTES",
          data: response.data.data.data,
          count: response.data.data.total_items,
          params,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const searchAndFilterTeamReminders = (orgId, params) => {
  return async (dispatch) => {
    const { page, perPage, q } = params || {};
    await axios
      .get(`/reminder/org/${orgId}`, {
        params: {
          page,
          page_size: perPage,
          text: q,
        },
      })
      .then((response) => {
        dispatch({
          type: "GET_TEAM_REMINDERS",
          data: response.data.data,
          count: response.data.data.length,
          params,
        });
      })
      .catch((err) => console.log(err));
  };
};

// release org superfone
export const releaseOrganizationSuperfone = (orgId, superfoneNumber) => {
  return async (dispatch) => {
    await axios
      .get(`/numbers/release/${superfoneNumber}`)
      .then(async (response) => {
        await generateNote({
          id: orgId,
          type: "org",
          action_name: "Release SF Number",
        });
        dispatch(getTeam(orgId));
        dispatch(getCallHistoryStats(orgId));
        MySwal.fire({
          icon: "success",
          title: "Superfone released successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      });
  };
};

// delete org
export const deleteOrganization = (id) => {
  return async (dispatch) => {
    await axios
      .delete(`/org/${id}`)
      .then(async (response) => {
        await generateNote({ id, type: "org", action_name: "Delete Org" });
        MySwal.fire({
          icon: "success",
          title: "Organization deleted successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      });
  };
};

// get all subscriptions
export const getAllSubscriptions = (orgId) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_TEAM_SUBSCRIPTIONS_LOADER", visiblity: true });
    await axios
      .get(`/subscription/org/${orgId}?order=DESC`)
      .then(async (response) => {
        /*
        Get Invoice link and other payment information
        Adding 'payment' object in subscription data
        */
        const subscriptions = response.data.data;
        // const paymentIds = subscriptions
        //   .map((s) => {
        //     return {
        //       order_id: s?.order?.id || "",
        //       external_payment_id: s?.order?.payment?.external_payment_id || "",
        //     };
        //   })
        //   .filter((e) => !!e?.external_payment_id);
        // const promises = paymentIds.map((e) => {
        //   return axios.get(
        //     `/orders/payments?page=1&page_size=1&text=${e.external_payment_id}`
        //   );
        // });
        // const responses = await Promise.allSettled(promises);
        // const payments = responses
        //   .filter((e) => e.status === "fulfilled")
        //   .map((e) => e?.value?.data?.data?.rows?.[0] || null)
        //   .filter((e) => !!e);
        // const updatedSubscriptions = subscriptions.map((s) => {
        //   return {
        //     ...s,
        //     payment:
        //       (payments || []).find((e) => {
        //         return e?.order_id === s?.order?.id;
        //       }) || null,
        //   };
        // });
        dispatch({
          type: "GET_TEAM_SUBSCRIPTIONS",
          data: subscriptions,
        });
      })
      .catch((err) => console.log(err))
      .then(() =>
        dispatch({
          type: "TOGGLE_GET_TEAM_SUBSCRIPTIONS_LOADER",
          visiblity: false,
        })
      );
  };
};

export const getAllUsers = (orgId) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_TEAM_USERS_LOADER", visiblity: true });
    await axios
      .get(`/users/org/${orgId}`)
      .then((response) => {
        dispatch({
          type: "GET_TEAM_USERS",
          data: response.data.data,
        });
      })
      .catch((err) => console.log(err))
      .then(() =>
        dispatch({ type: "TOGGLE_GET_TEAM_USERS_LOADER", visiblity: false })
      );
  };
};

export const updateTeamSize = (id, teamSize) => {
  return async (dispatch) => {
    await axios
      .post(`/subscription/team-size`, {
        org_id: id,
        team_size: teamSize,
      })
      .then(async (response) => {
        await generateNote({
          id,
          action_name: "Team size updated",
          type: "org",
        });
        dispatch(getAllSubscriptions(id));
        MySwal.fire({
          icon: "success",
          title: "Team size updated successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      });
  };
};

export const getOrgSettings = (id) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_TEAM_SETTINGS_LOADER", visiblity: true });
    await axios
      .get(`/settings/org/${id}`)
      .then((response) => {
        dispatch({
          type: "GET_TEAM_SETTINGS",
          data: response.data.data.settings || {},
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() =>
        dispatch({ type: "TOGGLE_GET_TEAM_SETTINGS_LOADER", visiblity: false })
      );
  };
};

export const updateCallRecordingSetting = (id, callRecordingBool) => {
  return async (dispatch) => {
    await axios
      .post(`/settings/org/${id}`, {
        call_recording: callRecordingBool,
      })
      .then(async (response) => {
        await generateNote({
          id,
          action_name: "Call Recording Setting Updated",
        });
        dispatch(getOrgSettings(id));
        MySwal.fire({
          icon: "success",
          title: "Call recording setting updated successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      });
  };
};

export const updateCallerTuneSetting = async (id, callerTuneBool) => {
  const dispatch = store.dispatch;
  return await axios
    .post(`/settings/org/${id}`, {
      caller_tune: callerTuneBool ? "custom" : "default",
    })
    .then(async (response) => {
      await generateNote({
        id,
        action_name: "Caller tune setting updated",
        type: "org",
      });
      dispatch(getOrgSettings(id));
      MySwal.fire({
        icon: "success",
        title: "Caller tune setting updated successfully",
        customClass: { confirmButton: "btn btn-primary" },
        buttonsStyling: false,
      });
    })
    .catch(() => {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        customClass: { confirmButton: "btn btn-primary" },
        buttonsStyling: false,
      });
    });
};

export const startNewTrial = (id) => {
  return async (dispatch) => {
    await axios
      .post(`/orders/start-new-trial/${id}`, {})
      .then(async (response) => {
        await generateNote({ id, type: "org", action_name: "Start new Trial" });
        dispatch(getOrgSettings(id));
        MySwal.fire({
          icon: "success",
          title: "New trial Started successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      });
  };
};

export const refreshTrunk = (orgid) => {
  return async (dispatch) => {
    await axios
      .post(`/subscription/refresh-trunk`, { org_id: orgid })
      .then((response) => {
        MySwal.fire({
          icon: "success",
          title: "Trunk Refreshed successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      });
  };
};

export const getKyc = (orgId) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_TEAM_KYC_LOADER", visiblity: true });
    await axios
      .get(`/org/kyc/${orgId}`)
      .then((response) => {
        dispatch({
          type: "GET_TEAM_KYC",
          data: response.data.data,
        });
      })
      .catch((err) => console.log(err))
      .then(() =>
        dispatch({ type: "TOGGLE_GET_TEAM_KYC_LOADER", visiblity: false })
      );
  };
};

export const startTrialForUser = (userId, orgId) => {
  return async (dispatch) => {
    await axios
      .post("/orders/start-trial/org", { user_id: userId })
      .then(async (response) => {
        await generateNote({
          id: orgId,
          action_name: "Start Trial for User",
          type: "org",
        });
        dispatch(getTeam(orgId));
        dispatch(getAllSubscriptions(orgId));
        dispatch(getCallHistoryStats(orgId));
        MySwal.fire({
          icon: "success",
          title: "Trial started successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      });
  };
};

export const getAllPaymentLinks = (orgId) => {
  return async (dispatch) => {
    dispatch({
      type: "TOGGLE_GET_TEAM_PAYMENT_LINKS_LOADER",
      visiblity: true,
    });
    await axios
      .get(`/payments/links/org/${orgId}`)
      .then((response) => {
        dispatch({
          type: "GET_TEAM_PAYMENT_LINKS",
          data: response.data.data,
        });
      })
      .catch((err) => console.log(err))
      .then(() =>
        dispatch({
          type: "TOGGLE_GET_TEAM_PAYMENT_LINKS_LOADER",
          visiblity: false,
        })
      );
  };
};

export const updateSubscriptionValidity = (
  subscriptionId,
  date,
  orgId,
  extra
) => {
  return async (dispatch) => {
    await axios
      .post("/subscription/update-validity", {
        subscription_id: subscriptionId,
        valid_till: date,
        ...extra,
        gstin: extra?.gstin || "",
      })
      .then(async (response) => {
        await generateNote({
          id: orgId,
          action_name: "Update Plan Validity",
          type: "org",
        });
        dispatch({ type: "RESET_DATE_PICKER_DATA" });
        dispatch(getAllSubscriptions(orgId));
        MySwal.fire({
          icon: "success",
          title: "Subscription validity changed successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      });
  };
};

export const endSubscription = (subscriptionId, orgId) => {
  return async (dispatch) => {
    await axios
      .post(`/subscription/end/${subscriptionId}`)
      .then(async (response) => {
        await generateNote({
          id: orgId,
          action_name: "End Plan",
          type: "org",
        });
        dispatch(getAllSubscriptions(orgId));
        MySwal.fire({
          icon: "success",
          title: "Plan ended successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      });
  };
};
export const updateSubscription = (subscriptionId, orgId, data) => {
  return async (dispatch) => {
    await axios
      .put(`/subscription/${subscriptionId}`, data)
      .then(async (response) => {
        await generateNote({
          id: orgId,
          action_name: "Plan Updated - " + getNoteValue(data),
          type: "org",
        });
        MySwal.fire({
          icon: "success",
          title: data?.freebies
            ? "Freebies Update successfully"
            : "Addons Update successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
        dispatch(getAllSubscriptions(orgId));
      })
      .catch((err) => {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      });
  };
};

export const getCallHistoryStats = (orgId) => {
  return async (dispatch) => {
    dispatch({
      type: "TOGGLE_GET_TEAM_CALL_HISTORY_STATS_LOADER",
      visiblity: true,
    });
    await axios
      .get(`/call-history/org/${orgId}/stats/total-call-and-duration`)
      .then((response) => {
        dispatch({
          type: "GET_TEAM_CALL_HISTORY_STATS",
          data: response.data.data,
        });
      })
      .catch((err) => console.log(err))
      .then(() =>
        dispatch({
          type: "TOGGLE_GET_TEAM_CALL_HISTORY_STATS_LOADER",
          visiblity: false,
        })
      );
  };
};

export const changeSFNumber = (org, data) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_TEAM_LOADER", visiblity: true });
    await axios
      .post("/subscription/change-number", {
        org_id: org.id,
        old_number: org.superfone_number.trim(),
        new_number: data.sf_number.trim(),
        paid: data.is_paid_number,
      })
      .then(async (response) => {
        await generateNote({
          id: org.id,
          type: "org",
          action_name: "Change SF Number",
        });
        dispatch(getTeam(org.id));
        MySwal.fire({
          icon: "success",
          title: "SF number changed successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.message}`,
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .then(() =>
        dispatch({ type: "TOGGLE_GET_TEAM_LOADER", visiblity: false })
      );
  };
};

export const assignSFNumber = (org, data) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_TEAM_LOADER", visiblity: true });
    await axios
      .post("/subscription/assign-number", {
        org_id: org.id,
        number: data.sf_number.trim(),
        paid: data.is_paid_number,
      })
      .then(async (response) => {
        await generateNote({
          id: org.id,
          action_name: "Assign SF Number",
          type: "org",
        });
        dispatch(getTeam(org.id));
        MySwal.fire({
          icon: "success",
          title: "SF number assigned successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.message}`,
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .then(() =>
        dispatch({ type: "TOGGLE_GET_TEAM_LOADER", visiblity: false })
      );
  };
};

export const getPlans = () => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_PLANS_LOADER", visiblity: true });
    await axios
      .get(`/subscription/plans/all`)
      .then((response) => {
        dispatch({
          type: "GET_PLANS",
          data: response.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() =>
        dispatch({ type: "TOGGLE_GET_PLANS_LOADER", visiblity: false })
      );
  };
};

export const createSubscription = (org, data, cb = () => {}) => {
  console.log(data);
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_TEAM_SUBSCRIPTIONS_LOADER", visiblity: true });
    await axios
      .post("/subscription", {
        org_id: org.id,
        plan: data.plan,
        variant: data.variant,
        payment_id: data.payment_id,
        start_date: data.start_date,
        order_type: data.order_type,
        ...(data?.addons && data.addons.length ? { addons: data.addons } : {}),
        ...(data?.amount ? { amount: Number(data.amount * 100) } : {}),
        ...(data?.payment_type ? { payment_type: data.payment_type } : {}),
        ...(data?.gstin ? { gstin: data.gstin } : {}),
        ...(data?.description ? { description: data.description } : {}),
      })
      .then(async (response) => {
        await generateNote({
          id: org.id,
          action_name: "Plan created",
          type: "org",
        });
        dispatch(getAllSubscriptions(org.id));
        cb(null);
        MySwal.fire({
          icon: "success",
          title: "Plan created successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.message}`,
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .then(() =>
        dispatch({
          type: "TOGGLE_GET_TEAM_SUBSCRIPTIONS_LOADER",
          visiblity: false,
        })
      );
  };
};
export const createTopup = (org, data, cb = () => {}) => {
  console.log(data);
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_TEAM_SUBSCRIPTIONS_LOADER", visiblity: true });
    await axios
      .post("/subscription/top-up", {
        org_id: org.id,
        payment_id: data.payment_id,
        talk_time_minutes: data.talktime,
        ...(data?.amount ? { amount: Number(data.amount * 100) } : {}),
        ...(data?.payment_type ? { payment_type: data.payment_type } : {}),
        ...(data?.gstin ? { gstin: data.gstin } : {}),
        ...(data?.description ? { description: data.description } : {}),
      })
      .then(async (response) => {
        await generateNote({
          id: org.id,
          action_name: "Topup  Added",
          type: "org",
        });
        cb(null);
        MySwal.fire({
          icon: "success",
          title: "Topup  Added successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.message}`,
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .then(() =>
        dispatch({
          type: "TOGGLE_GET_TEAM_SUBSCRIPTIONS_LOADER",
          visiblity: false,
        })
      );
  };
};

export const updateOrg = (orgId, data, cb = () => {}) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_TEAM_LOADER", visiblity: true });
    await axios
      .put(`/org/${orgId}`, data)
      .then(async (response) => {
        await generateNote({
          id: orgId,
          action_name: "Org Updated - " + getNoteValue(data),
          type: "org",
        });
        cb(null);
        dispatch(getTeam(orgId));
        MySwal.fire({
          icon: "success",
          title: "Org updated successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        console.log(err);
        cb(err);
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.message}`,
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .then(() =>
        dispatch({ type: "TOGGLE_GET_TEAM_LOADER", visiblity: false })
      );
  };
};

export const updateOrgSettings = (orgId, data, cb = () => {}) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_TEAM_LOADER", visiblity: true });
    await axios
      .post(`/settings/org/${orgId}`, data)
      .then(async (response) => {
        await generateNote({
          id: orgId,
          action_name: "Org Settings Updated - " + getNoteValue(data),
          type: "org",
        });
        cb(null);
        dispatch(getOrgSettings(orgId));
        MySwal.fire({
          icon: "success",
          title: "Org Settings updated successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        console.log(err);
        cb(err);
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.message}`,
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .then(() =>
        dispatch({ type: "TOGGLE_GET_TEAM_LOADER", visiblity: false })
      );
  };
};

export const getTeamPresence = (orgId) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_TEAM_PRESENCE_LOADER", visiblity: true });
    dispatch({ type: "RESET_GET_TEAM_PRESENCE_INFO" });
    await axios
      .get(`/presence/org/${orgId}`)
      .then((response) => {
        dispatch({
          type: "GET_TEAM_PRESENCE",
          data: response.data.data,
        });
      })
      .catch((err) => console.log(err))
      .then(() =>
        dispatch({ type: "TOGGLE_GET_TEAM_PRESENCE_LOADER", visiblity: false })
      );
  };
};

export const refreshSoftPhone = (userId, cb = () => {}) => {
  return async (dispatch) => {
    await axios
      .get(`/users/${userId}/reconnect-softphone`)
      .then((response) => {
        cb(null, true);
        MySwal.fire({
          icon: "success",
          title: "Refreshed successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        console.log(err);
        cb(err);
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.message}`,
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      });
  };
};

export const getTeamVoip = (superfoneNumber, cb = () => {}) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_TEAM_VOIP_LOADER", visiblity: true });
    dispatch({ type: "RESET_GET_TEAM_VOIP_INFO" });
    await axios
      .get(`/numbers/voip/${superfoneNumber}`)
      .then((response) => {
        dispatch({
          type: "GET_TEAM_VOIP",
          data: response.data.data,
        });
        cb(null);
      })
      .catch((err) => console.log(err))
      .then(() =>
        dispatch({ type: "TOGGLE_GET_TEAM_VOIP_LOADER", visiblity: false })
      );
  };
};

export const getTeamActiveVoiceMessage = (orgId) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_TEAM_VOICE_MESSAGE_LOADER", visiblity: true });
    dispatch({ type: "RESET_GET_TEAM_VOICE_MESSAGE_INFO" });
    await axios
      .get(`/voice-message/org/${orgId}`)
      .then((response) => {
        dispatch({
          type: "GET_TEAM_VOICE_MESSAGE",
          data: response.data.data,
        });
      })
      .catch((err) => console.log(err))
      .then(() =>
        dispatch({
          type: "TOGGLE_GET_TEAM_VOICE_MESSAGE_LOADER",
          visiblity: false,
        })
      );
  };
};

export const getCallerTune = async (orgId) => {
  try {
    const response = await axios.get(`/voice-message/org/${orgId}`);
    return response?.data?.data;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const setCallerTuneForOrg = async (payload) => {
  try {
    const response = await axios.post(`/voice-message/org`, payload);
    await generateNote({
      id: payload?.org_id,
      action_name: "Set Caller Tune",
      type: "org",
    });
    return response?.data?.data;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const setCustomCallerTune = async (payload) => {
  try {
    const response = await axios.post(`/voice-message/custom`, payload);
    await generateNote({
      id: payload?.org_id,
      action_name: "Set Custom Caller Tune",
      type: "org",
    });
    return response?.data?.data;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const searchAndFilterTeamVoipHistory = (orgId, params) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_TEAM_VOIP_HISTORY_LOADER", visiblity: true });
    const { page, perPage } = params || {};
    await axios
      .get(`/org/${orgId}/voip-history`, {
        params: {
          page,
          page_size: perPage,
        },
      })
      .then((response) => {
        dispatch({
          type: "SEARCH_TEAM_VOIP_HISTORY",
          data: response.data.data.rows,
          total: response.data.data.total_items,
          params,
        });
      })
      .catch((err) => console.log(err))
      .then(() => {
        dispatch({
          type: "TOGGLE_GET_TEAM_VOIP_HISTORY_LOADER",
          visiblity: false,
        });
      });
  };
};
export const getTalkTIme = (orgId, params) => {
  return async (dispatch) => {
    const { page, perPage } = params || {};
    await axios
      .get(`/subscription/talk-time/${orgId}`, {
        params: {
          page,
          page_size: perPage,
        },
      })
      .then((response) => {
        dispatch({
          type: "GET_TALKTIME",
          talkTime: response.data.data.talk_time,
          talkTimeLedger: response.data.data.talk_time_ledger,
          params,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const searchAndFilterTeamInternalLeads = (orgId, params) => {
  return async (dispatch) => {
    dispatch({
      type: "TOGGLE_GET_TEAM_INTERNAL_LEADS_LOADER",
      visiblity: true,
    });
    const { page, perPage } = params || {};
    await axios
      .get(`/admin/internal-lead/search`, {
        params: {
          page,
          page_size: perPage,
          org_id: orgId,
        },
      })
      .then((response) => {
        dispatch({
          type: "SEARCH_TEAM_INTERNAL_LEADS",
          data: response.data.data.rows,
          total: response.data.data.total_items,
          params,
        });
      })
      .catch((err) => console.log(err))
      .then(() => {
        dispatch({
          type: "TOGGLE_GET_TEAM_INTERNAL_LEADS_LOADER",
          visiblity: false,
        });
      });
  };
};

export const qualifyOrg = (orgId) => {
  return async (dispatch) => {
    await axios
      .post(`/admin/org/${orgId}/qualify`)
      .then(async (response) => {
        await generateNote({
          id: orgId,
          action_name: "Org Qualified",
          type: "org",
        });
        MySwal.fire({
          icon: "success",
          title: "Qualified successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
        dispatch(searchAndFilterTeamInternalLeads(orgId));
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.message}`,
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      });
  };
};
export const resetFup = (orgId) => {
  return async (dispatch) => {
    await axios
      .get(`/admin/org/reset-fup/${orgId}`)
      .then(async (response) => {
        await generateNote({
          id: orgId,
          action_name: "Reset FUP",
          type: "org",
        });
        MySwal.fire({
          icon: "success",
          title: "Reset FUP done successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.message}`,
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      });
  };
};

const getNoteValue = (value) => {
  if (typeof value === "object") {
    return Object.entries(value)
      .map((e) => `{${e[0]} : ${typeof e[1] === "object" ? "Updated" : e[1]}}`)
      .join(" and ");
  }
  return JSON.stringify(value);
};

export const createPayment = (payload) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_PLANS_LOADER", visiblity: true });
    await generateNote({
      id: payload.org_id,
      type: "org",
      action_name: "Create Invoice",
    });
    await axios
      .post(`/payments/custom`, payload)
      .then((response) => {})
      .catch((err) => {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.message}`,
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .then(() => {
        MySwal.fire({
          title: "Payment",
          text: "Payment created successfully",
          icon: "success",
          buttons: false,
          timer: 2000,
        });
        return dispatch({ type: "TOGGLE_GET_PLANS_LOADER", visiblity: false });
      });
  };
};

export const getAllEventActions = (orgId) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_TEAM_EVENT_ACTIONS_LOADER", visiblity: true });
    await axios
      .get(`/admin/event/action-setting/org/${orgId}`)
      .then(async (response) => {
        dispatch({
          type: "GET_TEAM_EVENT_ACTIONS",
          data: response.data.data,
        });
      })
      .catch((err) => console.log(err))
      .then(() =>
        dispatch({
          type: "TOGGLE_GET_TEAM_EVENT_ACTIONS_LOADER",
          visiblity: false,
        })
      );
  };
};

export const setUpdateEventActionId = (eventActionId) => {
  return {
    type: "UPDATE_EVENT_ACTION_ID",
    id: eventActionId,
  };
};

export const setViewEventActionId = (eventActionId) => {
  return {
    type: "VIEW_EVENT_ACTION_ID",
    id: eventActionId,
  };
};

export const createDefaultMissedCallEventAction = (orgId, cb = () => {}) => {
  return async (dispatch) => {
    dispatch({ type: "TOGGLE_GET_TEAM_EVENT_ACTIONS_LOADER", visiblity: true });
    await axios
      .post("/admin/event/action-setting/missed-call/default", {
        org_id: orgId,
      })
      .then(async (response) => {
        await generateNote({
          id: orgId,
          action_name: "Default missed call event action created",
          type: "org",
        });
        dispatch(getAllEventActions(orgId));
        cb(null);
        MySwal.fire({
          icon: "success",
          title: "Default Missed Call event action created successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        console.log(err);
        cb(err);
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.message}`,
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .then(() =>
        dispatch({
          type: "TOGGLE_GET_TEAM_EVENT_ACTIONS_LOADER",
          visiblity: false,
        })
      );
  };
};

export const updateEventActionSetting = (
  actionSettingId,
  orgId,
  data,
  cb = () => {}
) => {
  return async (dispatch) => {
    await axios
      .put(`/admin/event/action-setting/${actionSettingId}`, data)
      .then(async (response) => {
        await generateNote({
          id: orgId,
          action_name: "Action Setting Updated - " + getNoteValue(data),
          type: "org",
        });
        cb(null);
        MySwal.fire({
          icon: "success",
          title: "Action Setting Update Successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        cb(err);
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.message}`,
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      });
  };
};

export const createEventActionSetting = (orgId, data, cb = () => {}) => {
  return async (dispatch) => {
    await axios
      .post(`/admin/event/action-setting`, data)
      .then(async (response) => {
        await generateNote({
          id: orgId,
          action_name: "Action Setting Created - " + getNoteValue(data),
          type: "org",
        });
        cb(null);
        MySwal.fire({
          icon: "success",
          title: "Action Setting Created Successfully",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        cb(err);
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.message}`,
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: false,
        });
      });
  };
};
export const updateTalktime = (talktime, value) => {
  return async (dispatch) => {
    await axios
      .post(`/subscription/talk-time/${talktime.id}`, {talk_time: value})
      .then(async (response) => {
        await generateNote({
          id: talktime.org_id,
          action_name: `Talktime ${talktime.id} Updated to ${value}`,
          type: "org",
        });
        MySwal.fire({
          icon: "success",
          title: "Talktime Updated Successfully",
          customClass: {confirmButton: "btn btn-primary"},
          buttonsStyling: false,
        });
      })
      .catch((err) => {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.message}`,
          customClass: {confirmButton: "btn btn-primary"},
          buttonsStyling: false,
        });
      });
  };
};
export const setTemporaryOTPForOrgUsers = (orgId) => {
  return async (dispatch) => {
    await axios
      .post(`/auth/temp-otp/org/${orgId}`)
      .then((response) => {
        dispatch({
          type: "SET_TEMP_OTP",
          data: response.data.data || {},
        });
      })
      .catch((err) => console.log(err));
  };
};